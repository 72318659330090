<!-- 业务板块 -->
<template>
  <div class="intoHuadu-container">
    <navSecondPageVue type="BusinessSection"></navSecondPageVue>
  </div>
  <Tabs :routerProps="routerProps" v-if="tabShow"></Tabs>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" @hideTab="hideTab"></component>
    </keep-alive>
  </router-view>
</template>
<script setup>
import navSecondPageVue from '../components/navSecondPage.vue'
import Tabs from '../components/Tabs.vue'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
const routerProps = [
  {
    name: '房产开发',
    path: '/BusinessSection/Land'
  },
  {
    name: '建材贸易',
    path: '/BusinessSection/building'
  },
  {
    name: '物业商管',
    path: '/BusinessSection/Estate'
  }
]
const tabShow = ref(true)
function hideTab() {
  tabShow.value = false
}
const route = useRoute()
watch(
  () => route.fullPath,
  () => {
    if (route.fullPath === '"/BusinessSection/LandDetail"') {
      return
    }
    tabShow.value = true
  }
)
</script>
<style lang="stylus" scoped></style>
